export const HIDE_B2B_INTERNAL_ID = 'hide_b2b';
export const HIDE_BUSINESS_INTERNAL_ID = 'hide_business';
export const HIDE_MOBILE_APP_INTERNAL_ID = 'hide_mobile_app';
export const HIDE_PROJECTS_INTERNAL_ID = 'hide_projects';
export const HIDE_PRO_INTERNAL_ID = 'hide_pro';
export const HIDE_REGION_INTERNAL_ID = 'hide_region';
export const HIDE_SUBSCRIBE_INTERNAL_ID = 'hide_subscribe';
export const LANG_EXPERIMENT_INTERNAL_ID = 'header_lang_picker';
export const HIDE_REC_TECH_INTERNAL_ID = 'hide_rec_tech';
export const HIDE_AKIT_ID = 'hide_akit';
export const HIDE_PAYMENT_MIR_ID = 'hide_payment_mir';
export const HIDE_PAYMENT_KASPI_ID = 'hide_payment_kaspi';
export const ALLOW_COMPARISONS_INTERNAL_ID = 'allow_product_comparisons';
export const APPSFLYER_SCRIPT_INTERNAL_ID = 'appsflyer_script';
